.tooltip {
	position: absolute;
	transform: translate(-50%, -200%);
	opacity: 0;
	top: 50%;
	left: 50%;
	pointer-events: none;
	width: 160px;
	text-align: center;
	transition: opacity 0.3s;
	background-color: #78cd9b;
	padding: 6px 12px;
	border-radius: 4px;
	color: #333;
	z-index: 1;

	&::after {
		content: "";
		width: 0;
		height: 0;
		border: 6px solid transparent;
		border-top-color: #78cd9b;
		position: absolute;
		bottom: 0;
		left: 50%;
		transform: translate(-50%, 100%);
	}
}
.share-button-wrapper {
	position: relative;
}
