body {
	font-size: calc(10px + 2vmin);
	background-color: #fee;
	color: #333;
	transition: background-color 0.3s ease-out, color 0.3s ease-out;
	padding-top: 30px;
}

* {
	box-sizing: border-box;
}

h1 {
	font-family: "Goudy Bookletter 1911", serif;
}

.tagline {
	text-transform: uppercase;
	opacity: 0.7;
	font-weight: bold;
	letter-spacing: 0.06em;
	font-size: 12px;
	margin-top: -12px;
}

.App {
	text-align: center;
}

.App-header {
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
}

:root {
	--correct-color: rgba(63, 184, 113, 0.7);
	--correct-color-darker: rgba(43, 141, 84, 0.7);
	--almost-correct-color: rgba(255, 180, 40, 0.7);
	--almost-correct-color-lighter: rgba(250, 213, 143, 0.7);
	--wrong-color: transparent;

	/*rgba(255, 0, 0, 0.5); */
	.indicator-bar {
		--wrong-color: #eee;
	}

	.dark-mode .indicator-bar {
		--wrong-color: #666;
	}
}

.correct {
	background-color: var(--correct-color);

	.indicator-bar {
		display: none;
	}
}

.almost-correct {
	background-color: var(--almost-correct-color);
}

.cb-mode {
	.almost-correct {
		background-color: var(--almost-correct-color);
		background-image: linear-gradient(to right, var(--almost-correct-color-lighter), var(--almost-correct-color-lighter) 5px, var(--almost-correct-color) 5px, var(--almost-correct-color));
		background-size: 10px 100%;
	}

	.correct {
		background-image: linear-gradient(0deg, var(--correct-color) 50%, var(--correct-color-darker) 50%);
		background-size: 10px 10px;
	}
}

.wrong {
	background-color: var(--wrong-color);
}

.text-tiny {
	position: relative;
	display: block;
	text-transform: uppercase;
	font-size: 8px;
}

body.dark-mode {
	color: white;
	background-color: #282c34;

	.App-header {}
}

.guess-table-wrapper {
	max-width: calc(100% - 24px);
	overflow: auto;
	margin: 0 auto;
}

thead th {
	font-size: 10px;
	text-transform: uppercase;
	font-weight: bold;
	opacity: 0.8;
}

.flex-row {
	display: flex;
	justify-content: space-between;
	align-items: flex-end;
	max-width: 800px;
	margin: 0 auto;
}

.input-wrapper.flex-row {
	align-items: center;
}

.icon {
	width: 20px;
	height: 20px;
}

.magic-card {
	max-width: 300px;
	border-radius: 4.2%;
	margin-bottom: 24px;
}

@import url("https://fonts.googleapis.com/css2?family=Goudy+Bookletter+1911&display=swap");

@media all and (max-width: 700px) {
	body {
		padding-top: 12px;
	}

	.input-wrapper.flex-row {
		flex-direction: column-reverse !important;
		align-items: center;
		gap: 20px;
		max-width: calc(100vw - 24px);
		margin: 0 auto;
	}

	form,
	.form-input-wrapper {
		width: 100%;
	}

	.input-control {
		font-size: 20px !important;
		width: 100%;
	}

	form input[type="submit"] {
		display: none;
	}

	h1 {
		text-align: left;
		padding: 0 12px;
	}

	.tagline {
		text-align: left;
		margin-left: 12px;
	}
}