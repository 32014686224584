.guess-table {
	margin: 12px auto;
	max-width: 900px;
	width: 100%;
	border-collapse: collapse;

	td {
		padding: 6px 6px;
		vertical-align: middle;
		border: 1px solid #ccc;
		text-align: center;
		font-size: 14px;
	}

	.inidicator-bar {
		height: 10px;
		width: 100%;
		margin: 6px;
	}

	.guess-name {
		text-align: left;
	}
	.guess-mv span {
		background-color: #aaa;
		color: #333;
		min-width: 24px;
		line-height: 24px;
		border-radius: 100px;
		display: inline-block;
		font-size: 17px;
		padding: 0;
		box-shadow: 0 2px 0 rgba(0, 0, 0, 0.5);
		text-align: center;
		font-weight: bold;
	}
	.guess-colour {
		.colour-wrapper {
			display: grid;
			grid-template-columns: 1fr;
			gap: 2px;
			align-items: center;
			justify-items: center;
		}
		.colour-symbol {
			width: 14px;
			height: 14px;
			background: #ccc;
			border-radius: 100px;
			border: 2px solid rgba(0, 0, 0, 0.1);
			&--W {
				background: rgb(248, 248, 176);
			}
			&--U {
				background: rgb(47, 162, 238);
			}
			&--B {
				background: rgb(90, 3, 90);
			}
			&--R {
				background: rgb(179, 20, 20);
			}
			&--G {
				background: rgb(104, 179, 54);
			}
		}
		.bar-wrapper {
			display: grid;
			grid-template-columns: 1fr 4px;
			gap: 2px;
			.indicator-bar {
				height: 100%;
				width: 4px;
				// border: 1px solid black;
			}
		}
	}

	.guess-rarity {
		white-space: nowrap;
		.rarity-icon {
			width: 24px;
			height: 24px;
			border: 2px solid rgba(0, 0, 0, 0.3);
			border-top-width: 1px;
			border-left-width: 1px;
			background: linear-gradient(-45deg, rgb(0, 0, 0) 0%, rgb(99, 97, 97) 100%);
			display: inline-block;
			text-align: center;
			border-radius: 3px;
		}
		span {
			vertical-align: middle;
		}
		.rarity-icon.rarityU {
			background: linear-gradient(-45deg, rgb(105, 98, 98) 0%, rgb(219, 215, 215) 100%);
		}
		.rarity-icon.rarityR {
			background: linear-gradient(-45deg, rgb(173, 116, 2) 0%, rgb(247, 218, 131) 100%);
		}
		.rarity-icon.rarityM {
			background: linear-gradient(-45deg, rgb(201, 57, 5) 0%, rgb(255, 151, 113) 100%);
		}
	}
	.guess-type,
	.guess-subtype {
		text-align: left;
		white-space: nowrap;
	}

	.guess-type,
	.guess-subtype {
		height: inherit;
	}
	.bar-wrapper {
		display: flex;
		flex-direction: column;
		height: 100%;
		.indicator-bar {
			height: 4px;
			margin-top: auto;
		}
	}

	.up::after {
		content: " ▲";
	}
	.down::after {
		content: " ▼";
	}

	.set-icon {
		height: 24px;
		width: 24px;
		transition: filter 0.3s ease-out;
	}

	.set-icon.guessed-wrong {
		filter: invert(30%) sepia(100%) saturate(900%) hue-rotate(-40deg); /* Red */
	}
	.set-icon.guessed-almost-correct {
		filter: invert(60%) sepia(100%) saturate(600%) hue-rotate(0deg); /* Orange */
	}
	.set-icon.guessed-correct {
		filter: invert(40%) sepia(100%) saturate(500%) hue-rotate(100deg); /* Green */
	}
}

body.dark-mode .set-icon {
	filter: invert(1);
}

@media all and (max-width: 700px) {
	.guess-table td {
		font-size: 10px;
	}
}
