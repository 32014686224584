$containerWidth: 120px;
$containerHeight: $containerWidth;

$faceWidth: $containerWidth * 0.5;
$faceHeight: $faceWidth * 0.86;

$transitionDuration: 0.5s;
$animationDuration: 3s;

$angle: 53deg;
$ringAngle: -11deg;
$sideAngle: 360deg/5;
$opacity: 0.75;

$rotateX: -$angle;
$translateZ: $faceWidth * 0.335;
$translateY: -$faceHeight * 0.15;
$translateRingZ: $faceWidth * 0.75;
$translateRingY: $faceHeight * 0.78 + $translateY;
$translateLowerZ: $translateZ;
$translateLowerY: $faceHeight * 0.78 + $translateRingY;

@keyframes roll {
	10% {
		transform: rotateX(0deg) rotateY(0deg) rotateZ(0deg);
	}
	30% {
		transform: rotateX(120deg) rotateY(240deg) rotateZ(0deg) translateX(40px) translateY(40px);
	}
	50% {
		transform: rotateX(240deg) rotateY(480deg) rotateZ(0deg) translateX(-40px) translateY(-40px);
	}
	70% {
		transform: rotateX(360deg) rotateY(720deg) rotateZ(0deg);
	}
	90% {
		transform: rotateX(480deg) rotateY(960deg) rotateZ(0deg);
	}
}

.die-wrapper {
	margin: auto auto;
	position: relative;
	width: $containerWidth;
	height: $containerHeight;
	perspective: 1500px;
	transform: rotate(8deg);
	clip-path: circle(45%);
	transition: filter 1s ease-out;
	border: 3px solid transparent;

	.die {
		position: absolute;
		width: 100%;
		height: 100%;
		transform-style: preserve-3d;
		transition: transform $transitionDuration ease-out;
		transform: rotateX($rotateX);

		@for $i from 1 through 5 {
			&[data-face="#{$i}"] {
				$angleMultiplier: $i - 1;
				transform: rotateX(-$angle) rotateY($sideAngle * $angleMultiplier);
			}
		}

		@for $i from 16 through 20 {
			&[data-face="#{$i}"] {
				$angleMultiplier: $i - 15;
				transform: rotateX(-$angle + 180deg) rotateY(-$sideAngle * $angleMultiplier);
			}
		}

		@for $i from 6 through 10 {
			&[data-face="#{$i}"] {
				$angleMultiplier: $i - 6;
				transform: rotateX(-$ringAngle) rotateZ(180deg) rotateY($sideAngle * $angleMultiplier);
			}
		}

		@for $i from 11 through 15 {
			&[data-face="#{$i}"] {
				$angleMultiplier: $i - 8;
				transform: rotateX(-$ringAngle) rotateY(-$sideAngle * $angleMultiplier - $sideAngle/2);
			}
		}

		.face {
			$horizontalMargin: -$faceWidth * 0.5;

			position: absolute;
			left: 50%;
			top: 0;
			margin: 0 $horizontalMargin;
			border-left: $faceWidth * 0.5 solid transparent;
			border-right: $faceWidth * 0.5 solid transparent;
			border-bottom: $faceHeight solid rgba(10, 80, 150, $opacity);
			width: 0px;
			height: 0px;
			transform-style: preserve-3d;
			backface-visibility: hidden;
			transition: border-color 0.8s ease-out;

			counter-increment: steps 1;

			&:before {
				content: counter(steps);
				position: absolute;
				top: $faceHeight * 0.25;
				left: -$faceWidth;
				color: rgba(255, 255, 255, 0.4);
				transition: color 0.5s ease-out;
				font-size: $faceHeight * 0.4;
				font-weight: bold;
				text-align: center;
				line-height: $faceHeight * 0.88;
				width: $faceWidth * 2;
				height: $faceHeight;
			}

			@for $i from 1 through 5 {
				&:nth-child(#{$i}) {
					$angleMultiplier: $i - 1;
					transform: rotateY(-$sideAngle * $angleMultiplier) translateZ($translateZ) translateY($translateY) rotateX($angle);
				}
			}

			@for $i from 16 through 20 {
				&:nth-child(#{$i}) {
					$angleMultiplier: $i - 18;
					transform: rotateY($sideAngle * $angleMultiplier + $sideAngle/2) translateZ($translateLowerZ) translateY($translateLowerY) rotateZ(180deg) rotateX($angle);
				}
			}

			@for $i from 6 through 10 {
				&:nth-child(#{$i}) {
					$angleMultiplier: $i - 11;
					transform: rotateY(-$sideAngle * $angleMultiplier) translateZ($translateRingZ) translateY($translateRingY) rotateZ(180deg) rotateX($ringAngle);
				}
			}

			@for $i from 11 through 15 {
				&:nth-child(#{$i}) {
					$angleMultiplier: $i - 8;
					transform: rotateY($sideAngle * $angleMultiplier + $sideAngle/2) translateZ($translateRingZ) translateY($translateRingY) rotateX($ringAngle);
				}
			}

			&--current {
				border-bottom-color: rgba(10, 80, 150, $opacity + 0.05);
				&::before {
					color: rgba(255, 255, 255, 1);
				}
			}
		}
	}
}
.dark-mode {
	.die-wrapper {
		// filter: invert(1);
		.face {
			border-bottom: $faceHeight solid rgba(200, 60, 80, $opacity);
		}
	}
}
