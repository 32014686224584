.input-control {
	font-size: 16px;
	padding: 6px 12px;
	border-radius: 4px;
	border: 1px solid #ccc;
	display: inline-block;
	font-size: 32px;
}

form,
form input,
.form-input-wrapper {
	max-width: 100%;
}

.form-input-wrapper {
	position: relative;
	display: inline-block;
}
.autocomplete {
	background: white;
	width: 100%;
	position: absolute;
	margin: 0;
	left: 0;
	list-style-type: none;
	text-align: left;
	padding: 0;
	overflow-x: auto;
	max-height: 400px;
	z-index: 2;
	border: 1px solid #ccc;
}
.autocomplete li {
	color: black;
	margin: 0;
	padding: 3px 12px;
	border-top: 1px solid #ccc;
	font-size: 16px;
}

.card-active {
	background: #49d;
	color: white !important;
}
