.info-button,
.settings-button {
	position: fixed;
	top: 12px;
	right: 12px;
	border-radius: 200px;
	padding: 6px;
	vertical-align: middle;
	display: flex;
	filter: invert(1);
}
.settings-button {
	right: 54px;
}

.modal-dialog {
	max-width: 600px;
	font-size: 13px;

	span.correct,
	span.almost-correct {
		padding: 2px 4px;
	}
}

.dark-mode {
	.modal-content {
		background: #333;
	}
	.modal-backdrop {
		opacity: 0.9;
	}
	.btn-close,
	.btn-close:hover {
		filter: invert(1);
	}
}
