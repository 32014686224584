.switch {
	position: relative;
	display: inline-block;
	width: 60px;
	height: 34px;
	outline: none;
}
.switch input {
	position: absolute;
	top: -99999px;
	left: -99999px;
}
.slider {
	position: absolute;
	cursor: pointer;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: #ccc;
	-webkit-transition: 0.4s;
	transition: 0.4s;
	border-radius: 34px;
}
.slider:before {
	position: absolute;
	content: "";
	height: 26px;
	width: 26px;
	left: 4px;
	bottom: 4px;
	background-color: white;
	-webkit-transition: 0.4s;
	transition: 0.4s;
	border-radius: 50%;
}
input:checked + .slider {
	background-color: #2196f3;
}
input:focus + .slider {
	box-shadow: 0 0 1px #2196f3;
}
input:checked + .slider:before {
	-webkit-transform: translateX(26px);
	-ms-transform: translateX(26px);
	transform: translateX(26px);
}
