.ticker {
	// display: flex;
	// max-width: 100%;
	// overflow: hidden;
	width: 100%;

	.swiper-slide {
		width: 32px;
		padding: 4px 0 9px;
	}
	.swiper-wrapper {
		-webkit-transition-timing-function: linear !important;
		-o-transition-timing-function: linear !important;
		transition-timing-function: linear !important;
	}

	.set-icon {
		height: 24px;
		width: 24px;
		filter: invert(0.5);
		vertical-align: middle;
	}
	.dark-mode & .set-icon {
		filter: invert(1);
	}
	.set-icon.guessed-wrong {
		filter: invert(30%) sepia(100%) saturate(900%) hue-rotate(-40deg); /* Red */
	}
	.set-icon.guessed-almost-correct {
		filter: invert(60%) sepia(100%) saturate(600%) hue-rotate(0deg); /* Orange */
	}
	.set-icon.guessed-correct {
		filter: invert(40%) sepia(100%) saturate(500%) hue-rotate(100deg); /* Green */
	}
}
